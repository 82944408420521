import React from "react"

export default function ButtonComp({ icon, onClick }) {
  return (
    <button
      onClick={onClick}
      className="bg-yellow-500 p-2 rounded-full size-14 flex justify-center items-center cursor-pointer"
    >
      {icon}
    </button>
  )
}
