import { useState, useEffect } from "react"
import PT from "../utils/lang/PT"
import EN from "../utils/lang/EN"
import ES from "../utils/lang/ES"
// import DE from './lang/DE';
import FR from "../utils/lang/FR"
// import IT from './lang/IT';
// import NL from './lang/NL';
// import PL from './lang/PL';

const useStringFile = () => {
  const [currentLang, setCurrentLang] = useState("EN") // default to English

  useEffect(() => {
    const setLangByLocale = () => {
      const deviceLocale = "EN"

      switch (deviceLocale) {
        case "PT":
        case "EN":
        case "ES":
        case "DE":
        case "FR":
        case "IT":
        case "NL":
        case "PL":
          setCurrentLang(deviceLocale)
          break
        default:
          setCurrentLang("EN") // default to English
          break
      }
    }

    setLangByLocale()
  }, [])

  const getStrings = () => {
    switch (currentLang) {
      case "PT":
        return PT
      case "EN":
        return EN
      case "ES":
        return ES
      // case 'DE':
      //   return DE;
      case "FR":
        return FR
      // case 'IT':
      //   return IT;
      // case 'NL':
      //   return NL;
      // case 'PL':
      //   return PL;
      default:
        return EN
    }
  }

  return { currentLang, getStrings }
}

export default useStringFile
