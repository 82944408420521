import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  InstapaperShareButton,
  InstapaperIcon,
  TwitterIcon,
  WhatsappIcon,
  RedditIcon,
  RedditShareButton,
  EmailShareButton,
  EmailIcon,
  PinterestShareButton,
  PinterestIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';

const SocialShareButtons = ({ url, title }) => (
  <div className="flex space-x-2">
    <FacebookShareButton url={url} quote={title}>
      <FacebookIcon size={32} round />
    </FacebookShareButton>
    <TwitterShareButton url={url} title={title}>
      <TwitterIcon size={32} round />
    </TwitterShareButton>
    <WhatsappShareButton url={url} title={title}>
      <WhatsappIcon size={32} round />
    </WhatsappShareButton>
    <RedditShareButton url={url} title={title}>
      <RedditIcon size={32} round />
    </RedditShareButton>
    <PinterestShareButton url={url} title={title}>
      <PinterestIcon size={32} round />
    </PinterestShareButton>
    <TelegramShareButton url={url} title={title}>
      <TelegramIcon size={32} round />
    </TelegramShareButton>


    {/* Add more buttons as needed */}
  </div>
);

export default SocialShareButtons;