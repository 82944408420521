// src/App.js
import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import MainPage from "./pages/MainPage"
import IntroductionPage from "./pages/Introduction"
import Camera from "./pages/CameraPage"
import PhotoScreen from "./pages/PhotoScreen"
import Layout from "./Layout"
import Header from "./components/Header"
import Footer from "./components/Footer"

const App = () => {
  return (
    <Router>
      <Layout>
      {/* <Header/> */}
        <Routes>
          <Route exact path="/" element={<MainPage />} />
          <Route path="/introduction" element={<IntroductionPage />} />
          <Route path="/camera" element={<Camera />} />
          <Route path="/photo" element={<PhotoScreen state={{ photoUri: "https://via.placeholder.com/150", selectedUnit: "cm" }} />} />
        </Routes>
      {/* <Footer/> */}
      </Layout>
    </Router>
  )
}

export default App
