// src/Footer.js
import React, { useEffect, useState } from "react"

const Footer = () => {
  const [url, setUrl] = useState(window.location.pathname)

  useEffect(() => {
    setUrl(window.location.pathname)
  }, [window.location])

  return (
    <footer
      className={`bg-prim w-full p-4 text-center text-white mt-auto ${
        url === "/" ? "" : "rounded-t-3xl"
      }`}
    >
      <p>&copy; 2024 Size Matters. All rights reserved.</p>
    </footer>
  )
}

export default Footer
