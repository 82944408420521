export default {
    intro_description: "Aponte sua câmera para alguém e nosso algoritmo inovador analisará a roupa da pessoa para prever o tamanho do pênis. Isto é apenas para fins de entretenimento; nada disto é real.",
    inches: "polegadas",
    cm : "cm",
    click_to_start: "Clique na banana para começar",
    capture: "Capturar",
    click_to_take_another: "Clique para fazer outra medição",
    child_upto_4: "Pequena maravilha!",
    child_upto_6: "Mini prodígio a crescer!",
    child_upto_8: "Pequeno rebento!",
    child_upto_10: "Grande aventura do pequenino!",
    child_upto_12: "Quase um grande malandro!",
    child_default: "Fofo como um botão! 😊",
    teen_upto_8: "Júnior nem tão grande!",
    teen_upto_10: "A despontar!",
    teen_upto_12: "Atenção, mundo!",
    teen_upto_14: "A chegar lá... mais ou menos!",
    teen_upto_16: "Uau, olha para ti!",
    teen_default: "Jovem em destaque!",
    adult_upto_12: "Tamanho divertido!",
    adult_upto_14: "Alerta de grandalhão!",
    adult_upto_16: "Uau Nelly!",
    adult_upto_18: "Rir com o gigante!",
    adult_upto_20: "Monstruosamente grande!",
    adult_default: "Rompe o molde, grandão!",
    oldman_upto_8: "Pequeno tesouro intemporal!",
    oldman_upto_10: "Ainda em forma, sempre a mexer!",
    oldman_upto_12: "Velhinho dourado!",
    oldman_upto_14: "Arranha-céus à moda antiga!",
    oldman_upto_16: "Avô dos gigantes!",
    oldman_default: "Envelhecido como um bom vinho!",
    default_case: "Traçando novas dimensões!",
    big_size_mode: "Modo tamanho grande 🍆",
    funny_mode: "Modo engraçado 🤡",
    explaination: "Aponte a câmera para alguém! 📷",
    share: "Compartilhe com seus amigos! 📲",
    no_measurement: "Por favor, aponte a câmera para alguém antes de clicar em Capturar.",
}
