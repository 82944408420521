export default {
    intro_description: "Apunta tu cámara hacia alguien y nuestro innovador algoritmo analizará su vestimenta para predecir el tamaño del pene. Esto es solo para propósitos de entretenimiento; nada de esto es real.",
    inches: "pulgadas",
    cm : "cm",
    click_to_start: "Haz clic en el plátano para empezar",
    capture: "Capturar",
    click_to_take_another: "Haz clic para tomar otra medida",
    click_to_take_your_first_another: "Haz clic aquí para tomar una medida",
    child_upto_8: "Pequeño brote en camino! 🌱",
    child_upto_10: "¡La aventura acaba de comenzar! 🚀",
    child_default: "Creciendo y brillando! 🌟",    
    teen_upto_8: "Adolescente en brote! 🌱",
    teen_upto_10: "Capullo floreciente! 🌷",
    teen_upto_12: "Ramificándose! 🌳",
    teen_upto_14: "Haciendo olas! 🌊",
    teen_upto_16: "Atrapando el viento! 🍃",
    teen_default: "¡Sueño adolescente! 🌈",
    adult_upto_8: "Compacto y genial! 😎",
    adult_upto_10: "Pequeño pero poderoso! 🚗",
    adult_upto_12: "Mago de tamaño medio! 🎩",
    adult_upto_14: "Avanzando con estilo! 👠",
    adult_upto_16: "¡Lleno de sorpresas! 🎁",
    adult_upto_18: "¡Altos cuentos para contar! 📖",
    adult_upto_20: "¡El cielo es el límite! 🌌",
    adult_upto_22: "Risas gigantescas! 😂",
    adult_default: "¡Único en su especie! 🦄",
    oldman_upto_8: "Compacto clásico! 📻",
    oldman_upto_10: "Envejecido a la perfección! 🍷",
    oldman_upto_12: "El groover de la edad dorada! 🎵",
    oldman_upto_14: "Entretenedor eterno! 🎤",
    oldman_upto_16: "Tamaño sabio! 📜",
    oldman_upto_18: "Medida mística! 🌙",
    oldman_upto_20: "Titán atemporal! ⏳",
    oldman_default: "Sabio y maravilloso! 🦉",
    default_case: "Trazando nuevas dimensiones! 🌌",
    no_measurement: "Por favor, apunta la cámara a alguien antes de hacer clic en Capturar.",

}
