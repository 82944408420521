import React, { useEffect, useRef, useState, useCallback } from "react"
import * as posenet from "@tensorflow-models/posenet"
import "@tensorflow/tfjs"
import "../styles/App.css"
import { FaCamera, FaCloudUploadAlt } from "react-icons/fa"
import { RiCameraSwitchFill } from "react-icons/ri"
import ImageUploader from "../components/imageUploader"
import ButtonComp from "../components/ButtonComp"
import { useNavigate } from "react-router-dom"

import Webcam from "react-webcam"
import useStringFile from "../hooks/useStringFile"
// @ts-ignore
import banana_1 from "../images/banana_1.png"
// @ts-ignore
import banana_2 from "../images/banana_2.png"
// @ts-ignore
import banana_3 from "../images/banana_3.png"
// @ts-ignore
import banana_4 from "../images/banana_4.png"
// @ts-ignore
import banana_5 from "../images/banana_5.png"
import useWindowWidthHeight from "../hooks/useWindowWidthHeight"
import SocialShareButtons from "../components/SocialShareButtons"

const Camera = () => {
  const canvasRef = useRef(null)
  const videoRef = useRef(null)
  const counterRef = useRef(0)
  const spinnerAngle = useRef(0)

  const navigate = useNavigate()

  const { currentLang, getStrings } = useStringFile()
  const strings = getStrings()

  const lastPoses = useRef([])
  const [selectedUnit, setSelectedUnit] = useState(
    localStorage.getItem("unit") || "cm"
  )
  const requestInProgress = useRef(false)
  const measurement = useRef(null)
  const currentString = useRef(null)
  const [facingMode, setFacingMode] = useState("environment")
  const [screenShot, setScreenShot] = useState(null)
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState(null)
  const [device, setDevice] = useState(null)
  const dimensions = useWindowWidthHeight()

  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  let aspectRatio = 16 / 9 // default aspect ratio

  // // Detect if the device is iOS
  // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //   aspectRatio = 3 / 2 // typical iOS aspect ratio
  // }

  // // Detect if the device is Android
  // if (/android/i.test(userAgent)) {
  //   aspectRatio = 16 / 9 // typical Android aspect ratio
  // }
  useEffect(() => {
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      aspectRatio = 4 / 3 // typical iOS aspect ratio
      setDevice("iOS")
    } else if (/android/i.test(userAgent)) {
      aspectRatio = 16 / 9 // typical Android aspect ratio
      setDevice("Android")
    } else {
      aspectRatio = 16 / 9 // default aspect ratio
      setDevice("Desktop")
    }
  }, [userAgent])

  useEffect(() => {
    console.log("Device: ", device)
  }, [device])

  const screenRatio = dimensions.width / dimensions.height
  let width, height

  if (screenRatio > aspectRatio) {
    width = dimensions.height * aspectRatio
    height = dimensions.height
  } else {
    const minHeight = dimensions.height * 0.5 // 50% of the original height
    height = dimensions.width / aspectRatio

    if (height < minHeight) {
      height = minHeight
      width = height * aspectRatio
    } else {
      width = dimensions.width
    }
  }

  useEffect(() => {
    const setupCamera = async () => {
      const video = videoRef.current.video
      video.width = window.innerWidth
      video.height = window.innerHeight

      return new Promise((resolve, reject) => {
        navigator.mediaDevices
          .getUserMedia({
            video: { facingMode: facingMode },
          })
          .then((stream) => {
            video.srcObject = stream
            video.onloadedmetadata = () => {
              resolve(video)
            }
          })
          .catch((error) => {
            console.error("Error accessing the camera: ", error)
            reject(error)
          })
      })
    }

    const loadAndPredict = async () => {
      const net = await posenet.load()
      const video = await setupCamera()

      video.play()

      const canvas = canvasRef.current
      const ctx = canvas.getContext("2d")
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight

      const poseDetectionFrame = async () => {
        const pose = await net.estimateSinglePose(video, {
          flipHorizontal: false,
        })
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

        renderPose(pose, ctx)

        requestAnimationFrame(poseDetectionFrame)
      }

      poseDetectionFrame()
    }

    loadAndPredict()
  }, [facingMode])

  useEffect(() => {
    const canvas = canvasRef.current

    if (!canvas) return

    const ctx = canvas.getContext("2d")

    const handleClick = (e) => {
      console.log(e)
      fetchMeasurement()
    }

    canvas.addEventListener("mousedown", handleClick)
  }, [])

  const renderMeasurementTextString = (measurement, age, unit = "cm") => {
    if (measurement == null || age == null) return null

    const child = age <= 13
    const teen = age > 13 && age <= 18
    const adult = age > 18 && age <= 40
    const oldman = age > 40

    const matrix = {
      child: {
        upto_4: strings.child_upto_4,
        upto_6: strings.child_upto_6,
        upto_8: strings.child_upto_8,
        upto_10: strings.child_upto_10,
        upto_12: strings.child_upto_12,
        default: strings.child_default,
      },
      teen: {
        upto_8: strings.teen_upto_8,
        upto_10: strings.teen_upto_10,
        upto_12: strings.teen_upto_12,
        upto_14: strings.teen_upto_14,
        upto_16: strings.teen_upto_16,
        default: strings.teen_default,
      },
      adult: {
        upto_12: strings.adult_upto_12,
        upto_14: strings.adult_upto_14,
        upto_16: strings.adult_upto_16,
        upto_18: strings.adult_upto_18,
        upto_20: strings.adult_upto_20,
        default: strings.adult_default,
      },
      oldman: {
        upto_8: strings.oldman_upto_8,
        upto_10: strings.oldman_upto_10,
        upto_12: strings.oldman_upto_12,
        upto_14: strings.oldman_upto_14,
        upto_16: strings.oldman_upto_16,
        default: strings.oldman_default,
      },
    }

    if (child) {
      if (measurement <= 4)
        return { text: matrix.child.upto_4, image: banana_1 }
      if (measurement <= 6)
        return { text: matrix.child.upto_6, image: banana_2 }
      if (measurement <= 8)
        return { text: matrix.child.upto_8, image: banana_3 }
      if (measurement <= 10)
        return { text: matrix.child.upto_10, image: banana_4 }
      if (measurement <= 12)
        return { text: matrix.child.upto_12, image: banana_5 }

      return { text: matrix.child.default, image: banana_5 }
    }

    if (teen) {
      if (measurement <= 8) return { text: matrix.teen.upto_8, image: banana_1 }
      if (measurement <= 10)
        return { text: matrix.teen.upto_10, image: banana_2 }
      if (measurement <= 12)
        return { text: matrix.teen.upto_12, image: banana_3 }
      if (measurement <= 14)
        return { text: matrix.teen.upto_14, image: banana_4 }
      if (measurement <= 16)
        return { text: matrix.teen.upto_16, image: banana_5 }

      return { text: matrix.teen.default, image: banana_5 }
    }

    if (adult) {
      if (measurement <= 12)
        return { text: matrix.adult.upto_12, image: banana_1 }
      if (measurement <= 14)
        return { text: matrix.adult.upto_14, image: banana_2 }
      if (measurement <= 16)
        return { text: matrix.adult.upto_16, image: banana_3 }
      if (measurement <= 18)
        return { text: matrix.adult.upto_18, image: banana_4 }
      if (measurement <= 20)
        return { text: matrix.adult.upto_20, image: banana_5 }
      return { text: matrix.adult.default, image: banana_5 }
    }

    if (oldman) {
      if (measurement <= 8) return matrix.oldman.upto_8, banana_1
      if (measurement <= 10) return matrix.oldman.upto_10, banana_2
      if (measurement <= 12) return matrix.oldman.upto_12, banana_3
      if (measurement <= 14) return matrix.oldman.upto_14, banana_4
      if (measurement <= 16) return matrix.oldman.upto_16, banana_5

      return matrix.oldman.default, banana_5
    }

    return strings.default_case
  }

  const fetchMeasurement = async () => {
    setLoading(true)

    if (requestInProgress.current) return

    requestInProgress.current = true
    try {
      const canvas = canvasRef.current

      const dataUrl = canvas.toDataURL("image/jpeg")

      const base64Image = dataUrl.replace(/^data:image\/jpeg;base64,/, "")

      console.log("Making request to API /api/measure")

      const response = await fetch(
        "https://sizemattersapi.bitnata.com/api/measure",
        {
          method: "POST",
          body: JSON.stringify({
            image: base64Image,
            poses: lastPoses.current[0].keypoints,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
          },
        }
      )

      if (response.status !== 200) {
        console.log("Bad request ", response.status, response.statusText)
        return
      }

      const data = await response.json()
      measurement.current = data.measurement
      const measurement_string = renderMeasurementTextString(
        data.measurement,
        data.age,
        selectedUnit
      )

      currentString.current = measurement_string
    } catch (error) {
      console.error("Error:", error)
    } finally {
      requestInProgress.current = false
      setLoading(false)
    }
  }

  const renderPose = useCallback(
    (pose, ctx) => {
      let rightHip, leftHip
      for (let i = 0; i < pose.keypoints.length; i++) {
        const k = pose.keypoints[i]
        if ((k.score ?? 0) <= 0.5) continue
        // if (k.part === "rightEye") rightHip = k
        // if (k.part === "leftEye") leftHip = k
        if (k.part === "rightHip") rightHip = k
        if (k.part === "leftHip") leftHip = k
      }

      if (!rightHip || !leftHip) return

      const midX = (rightHip.position.x + leftHip.position.x) / 2
      const midY = (rightHip.position.y + leftHip.position.y) / 2
      const distance = Math.sqrt(
        Math.pow(rightHip.position.x - leftHip.position.x, 2) +
          Math.pow(rightHip.position.y - leftHip.position.y, 2)
      )

      ctx.beginPath()
      ctx.arc(midX, midY, distance / 3, 0, 2 * Math.PI)
      ctx.lineWidth = 3
      ctx.strokeStyle = "#00627B"
      ctx.stroke()

      lastPoses.current = [pose]

      if (requestInProgress.current) {
        const drawSpinner = () => {
          ctx.save()
          ctx.translate(midX, midY)
          ctx.rotate(spinnerAngle.current)
          ctx.strokeStyle = "#00627B"
          ctx.lineWidth = 4
          ctx.beginPath()
          ctx.arc(0, 0, 20, 0, Math.PI * 1.5)
          ctx.stroke()
          ctx.restore()
          spinnerAngle.current += 0.1
          if (loading) {
            requestAnimationFrame(drawSpinner)
          }
        }
        drawSpinner()
      } else if (measurement.current !== null) {
        const measurementValue = measurement.current
        const img = new Image()
        img.src = currentString.current.image
        img.onload = () => {
          const aspectRatio = img.width / img.height
          const newHeight = img.height * 0.03 // 50% of the original height
          const newWidth = newHeight * aspectRatio // Adjust the width to maintain the aspect ratio

          ctx.drawImage(
            img,
            midX - newWidth / 2,
            midY - newHeight / 2,
            newWidth,
            newHeight
          )
        }
        ctx.beginPath()

        // Calculate text widths
        ctx.font = "21px Arial"
        const measurementText = `${
          selectedUnit === "cm"
            ? measurementValue
            : (measurementValue / 2.54).toFixed(2)
        } ${selectedUnit}`
        const measurementTextWidth = ctx.measureText(measurementText).width

        ctx.font = "18px Arial"
        const measurementValueWidth = ctx.measureText(measurementValue).width

        ctx.font = "14px Arial"
        const clickToTakeAnotherWidth = ctx.measureText(
          strings.click_to_take_another
        ).width
        const currentStringTextWidth = ctx.measureText(
          currentString.current.text
        ).width

        // Calculate max width required
        const maxWidth =
          Math.max(
            measurementTextWidth,
            measurementValueWidth,
            clickToTakeAnotherWidth,
            currentStringTextWidth
          ) + 30

        ctx.fillStyle = "rgba(0, 0, 0, 0.5)"
        ctx.fillRect(midX + 15, midY - 10, maxWidth, 100)

        ctx.fillStyle = "white"
        ctx.font = "21px Arial"
        ctx.fillText(measurementText, midX + 30, midY + 20)

        ctx.fillText(currentString.current.text, midX + 30, midY + 40)

        ctx.font = "14px Arial"
        ctx.fillText(strings.click_to_take_another, midX + 30, midY + 60)
      } else {
        ctx.fillStyle = "rgba(0, 0, 0, 0.5)"
        // button.rect(midX - 140, midY - 15, 330, 40);
        ctx.fillStyle = "#00627B"
        ctx.fillRect(midX - 140, midY - 15, 330, 40)

        ctx.fillStyle = "white"
        ctx.font = "19px Arial"

        ctx.fillText(
          strings.click_to_take_your_first_another,
          // midX + 20,
          midX - 120,

          midY + 8
        )
      }
    },
    [requestInProgress, measurement, selectedUnit, loading]
  )

  const handleCameraSwitch = () => {
    setFacingMode((prev) =>
      prev === "user" ? { exact: "environment" } : "user"
    )
  }

  const capture = useCallback(() => {
    const imageSrc = videoRef.current.getScreenshot()
    setScreenShot(imageSrc)
    // navigate("/photo", {
    //   state: {
    //     photoUri: imageSrc,
    //     selectedUnit: selectedUnit,
    //   },
    // })
  }, [])

  useEffect(() => {
    if (preview) setScreenShot(preview)
  }, [preview])

  console.log(preview)

  console.log({ width, height })

  // return (
  //   <div className="">
  //     <div className="flex justify-center max-w-[600px] w-full">
  //       {!screenShot && (
  //         <Webcam
  //           ref={videoRef}
  //           className="max-w-[600px] w-full h-[450px]"
  //           videoConstraints={{ facingMode }}
  //           screenshotFormat="image/jpeg"
  //         />
  //       )}

  //       {screenShot && (
  //         <img
  //           src={screenShot}
  //           alt="img"
  //           className="max-w-[600px] w-full h-[450px]"
  //         />
  //       )}

  //       {/* {preview && (
  //         <img
  //           src={preview}
  //           alt="Preview"
  //           className="max-w-[600px] w-full h-[450px]"
  //         />
  //       )} */}

  //       <canvas
  //         ref={canvasRef}
  //         className="absolute text-center z-10 mx-auto max-w-[600px] w-full h-[450px]"
  //       ></canvas>
  //     </div>

  //   </div>
  // )

  const WebCanvas = () => {
    if (width > 650)
      return (
        <canvas
          ref={canvasRef}
          className="absolute text-center z-10 mx-auto max-w-[600px] w-full h-[450px]"
        ></canvas>
      )

    if (width < 650) {
      if (device === "iOS") {
        return (
          <canvas
            ref={canvasRef}
            className="absolute text-center z-10 mx-auto max-w-[600px] w-full h-[450px]"
          ></canvas>
        )
      }

      return (
        <canvas
          ref={canvasRef}
          style={{
            width,
            height,
            position: "absolute",
            zIndex: 10,
          }}
        ></canvas>
      )
    }
  }

  const MobCanvas = () => (
    // "absolute text-center z-10 mx-auto max-w-[600px] w-full h-[450px]"
    <canvas
      ref={canvasRef}
      style={
        device === "iOS"
          ? {
              height: 450,
              maxWidth: 600,
              objectFit: "cover",
              position: "absolute",
              zIndex: 10,
              margin: "auto",
            }
          : {
              width,
              height,
              position: "absolute",
              zIndex: 10,
            }
      }
    />
  )

  const WebVideo = () => {
    if (width > 650)
      return (
        <Webcam
          ref={videoRef}
          className="max-w-[600px] w-full h-[450px]"
          videoConstraints={{ facingMode }}
          screenshotFormat="image/jpeg"
        />
      )

    // monile
    if (width < 650) {
      if (device === "iOS")
        return (
          <Webcam
            ref={videoRef}
            className="max-w-[600px] w-full h-[450px]"
            videoConstraints={{ facingMode }}
            screenshotFormat="image/jpeg"
          />
        )
      return (
        <Webcam
          ref={videoRef}
          style={{
            height: height,
            width: width,
          }}
          videoConstraints={{ facingMode }}
          screenshotFormat="image/jpeg"
        />
      )
    }
  }

  return (
    <div>
      <div className="flex justify-center max-w-[600px] w-full relative">
        {!screenShot && WebVideo()}

        {screenShot && (
          <img
            src={screenShot}
            alt="img"
            className="max-w-[600px] w-full h-[450px]"
          />
        )}

        {WebCanvas()}
      </div>

      {screenShot && (
        <div className="mx-auto mt-5 w-fit">
          <SocialShareButtons url={screenShot} title="Check this out!" />
        </div>
      )}

      <div
        className="bg-prim rounded-xl p-5 flex justify-between items-center my-5 max-w-[600px] w-[95%] mx-auto z-20"
        style={{ marginTop: "32px" }} // Adjust '32px' to the desired space
      >
        <ImageUploader setPreview={setPreview} />

        <ButtonComp
          onClick={capture}
          icon={<FaCamera className="text-prim" size={24} />}
        />
        <ButtonComp
          onClick={handleCameraSwitch}
          icon={<RiCameraSwitchFill className="text-prim" size={24} />}
        />
      </div>
    </div>
  )
}

export default Camera
