export default {
    intro_description: "Pointez votre caméra vers quelqu'un et notre algorithme innovant analysera sa tenue pour prédire la taille du pénis. Ceci est uniquement à des fins de divertissement ; rien de tout cela n'est réel.",
    inches: "pouces",
    cm : "cm",
    click_to_start: "Cliquez sur la banane pour commencer",
    capture: "Capturer",
    click_to_take_another: "Cliquez pour prendre une autre mesure",
    click_to_take_your_first_another: "Cliquez ici pour prendre une mesure",
    child_upto_4: "Petite merveille!",
    child_upto_6: "Mini prodige en herbe!",
    child_upto_8: "Petit bourgeon!",
    child_upto_10: "Grande aventure de PeeWee!",
    child_upto_12: "Presque un grand garçon!",
    child_default: "Mignon comme un bouton! 😊",
    teen_upto_8: "Junior pas si gros!",
    teen_upto_10: "En montée!",
    teen_upto_12: "Attention, monde!",
    teen_upto_14: "Presque là... en quelque sorte!",
    teen_upto_16: "Whoa, regarde-toi!",
    teen_default: "Ado titan!",
    adult_upto_12: "Mec format poche!",
    adult_upto_14: "Alerte grand gaillard!",
    adult_upto_16: "Oh Nelly!",
    adult_upto_18: "Rires pour ce géant!",
    adult_upto_20: "Monstrueusement massif!",
    adult_default: "Sors du lot, grand mec!",
    oldman_upto_8: "Petit trésor intemporel!",
    oldman_upto_10: "Toujours vaillant!",
    oldman_upto_12: "Vieux doré!",
    oldman_upto_14: "Gratte-ciel à l'ancienne!",
    oldman_upto_16: "Grand-père des géants!",
    oldman_default: "Vieilli comme un bon vin!",
    default_case: "Tracer de nouvelles dimensions! 🌌",
    big_size_mode: "Mode grande taille 🍆",
    funny_mode: "Mode drôle 🤡",
    explaination: "Pointez la caméra vers quelqu'un! 📷",
    share: "Partagez avec vos amis! 📲",
}
