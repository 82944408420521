import React, { useState } from "react"
import ButtonComp from "./ButtonComp"
import { FaCloudUploadAlt } from "react-icons/fa"

function ImageUploader({ setPreview }) {
  const [selectedFile, setSelectedFile] = useState(null)

  console.log(selectedFile)

  const handleFileChange = (event) => {
    const file = event.target.files[0]

    setSelectedFile(file)
    setPreview(URL.createObjectURL(file))
  }

  return (
    <div>
      <div className="relative">
        <input
          type="file"
          id="file-upload"
          className="hidden"
          onChange={handleFileChange}
        />

        <ButtonComp
          // onClick={handleUpload}
          icon={
            <label htmlFor="file-upload" className="cursor-pointer">
              <FaCloudUploadAlt className="text-prim" size={24} />
            </label>
          }
        />
      </div>
    </div>
  )
}

export default ImageUploader
