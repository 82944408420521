import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from "react-share"
import AdSense from "react-adsense" // This is hypothetical; adjust based on actual package usage
// import AdComponent from '../components/AdComponent'; // This is hypothetical; adjust based on actual component usage
import SocialShareButtons from "../components/SocialShareButtons" // This is hypothetical; adjust based on actual component usage

const PhotoScreen = () => {
  const navigate = useNavigate()
  const state = useLocation()
  console.log(state)
  const { state: _state, selectedUnit } = state
  const [adLoaded, setAdLoaded] = useState(false)

  useEffect(() => {
    // Example of how you might handle ad loading
    const loadAd = () => {
      console.log("Ad Loaded")
      setAdLoaded(true)
    }

    loadAd()

    return () => {
      // Cleanup ad if necessary
    }
  }, [])

  const handleInstagramShare = () => {
    alert("Open Instagram and paste the link!")
  }

  const handleTikTokShare = () => {
    alert("Open TikTok and use the video link!")
  }

  const handleShare = async () => {
    // Sharing is handled via react-share buttons directly in the render
  }

  const handleBackPress = () => {
    navigate(-1)
  }

  return (
    <div className="flex flex-col items-center w-full max-w-4xl mx-auto bg-gray-100">
      <div className="w-full bg-prim p-5 flex justify-between items-center rounded-b-3xl mb-5">
        <button onClick={handleBackPress} className="text-white">
          <i className="fa fa-arrow-left"></i> Back
        </button>
        <div>{/* Logo here */}</div>
        <div className="opacity-0">
          {/* Placeholder for balance */}
          <i className="fa fa-share-square-o"></i>
        </div>
      </div>
      <img src={_state.photoUri} className="w-11/12 h-60%" alt="Captured" />

      <div className="my-4">
        <SocialShareButtons url={_state.photoUri} title="Check this out!" />
      </div>

      {adLoaded && (
        // <AdComponent adClient="ca-pub-yourclientid" adSlot="youradslotid" />
        <></>
      )}
    </div>
  )
}

export default PhotoScreen
