export default {
    intro_description: "Point your camera at someone, our innovative algorithm will analyze theris outfit and predict the penis size, this is just for entertaining proposes, note that the results are not attached to true reality.",
    inches: "inches",
    cm : "cm",
    click_to_start: "Click in the banana to start",
    capture: "Capture",
    click_to_take_another: "Click to take another measurement",
    click_to_take_your_first_another: "Click here to take a measurmement",
    child_upto_4: "Wee little wonder!",
    child_upto_6: "Mini marvel in the making!",
    child_upto_8: "Itty-bitty sprout!",
    child_upto_10: "Peewee's big adventure!",
    child_upto_12: "Almost a big shot!",
    child_default: "Cute as a button!",
    teen_upto_8: "Not-so-jumbo junior!",
    teen_upto_10: "Up and comer!",
    teen_upto_12: "Watch out world!",
    teen_upto_14: "Getting there… kinda!",
    teen_upto_16: "Whoa, look at you!",
    teen_default: "Teen titan!",
    adult_upto_12: "Fun-sized fella!",
    adult_upto_14: "Big dude alert!",
    adult_upto_16: "Woah Nelly!",
    adult_upto_18: "Giggle at that giant!",
    adult_upto_20: "Monstrously massive!",
    adult_default: "Break the mold, big guy!",
    oldman_upto_8: "Tiny timeless treasure!",
    oldman_upto_10: "Still kickin', still tickin'!",
    oldman_upto_12: "Oldie goldie!",
    oldman_upto_14: "Old-school skyscraper!",
    oldman_upto_16: "Granddaddy of giants!",
    oldman_default: "Aged like fine wine!",
    default_case: "Monstrously massive!",
    big_size_mode: "Big size mode 🍆",
    funny_mode: "Funny mode 🤡",
    explaination: "Point the camera to someone! 📷",
    share: "Share with your friends! 📲",
    no_measurement: "Please point the camera to someone before clicking Capture.",
}

