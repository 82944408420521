// src/IntroductionPage.js
import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import logo from "../images/logo.png"
import topbananas from "../images/top_bananas.png"
import banana from "../images/banana_4.png"
import { Adsense } from "@ctrl/react-adsense"

const IntroductionPage = () => {
  const [unit, setUnit] = useState("cm")

  useEffect(() => {
    localStorage.setItem("unit", unit)
  }, [unit])

  const navigate = useNavigate()

  const toggleSwitch = () => {
    setUnit((prevUnit) => (prevUnit === "cm" ? "inches" : "cm"))
  }

  const handleStartClick = () => {
    navigate("/camera", { state: { selectedUnit: unit } })
  }

  return (
    <div className="flex flex-col items-center max-w-[600px] flex-1">
      {/* <img src={topbananas} alt="Top Bananas" className="w-full object-contain bg-prim h-1/4" /> */}
      <div className="w-full bg-prim flex flex-col items-center justify-center rounded-b-3xl">
        <img src={logo} alt="Logo" className="w-1/4 h-1/4 object-contain" />
      </div>
      <p className="text-lg text-center mt-4 mx-4 font-bold text-prim">
        Welcome to our App! Please select a unit and start the camera.
      </p>
      <div className="flex items-center mt-4">
        <span className="mr-2">cm</span>
        <label className="switch">
          <input
            type="checkbox"
            onChange={toggleSwitch}
            checked={unit === "inches"}
          />
          <span className="slider round"></span>
        </label>
        <span className="ml-2">inches</span>
      </div>
      <button
        className="bg-prim w-16 h-16 rounded-full flex items-center justify-center mt-4"
        onClick={handleStartClick}
      >
        <img src={banana} alt="Start" className="w-10 h-10 object-contain" />
      </button>
      <p className="text-prim text-lg font-bold mt-4">Click to Start</p>

      {/* <Adsense
        client="ca-pub-7640562161899788"
        slot="7259870550"
        style={{ width: 500, height: 300 }}
        format=""
      /> */}
    </div>
  )
}

export default IntroductionPage
