// src/MainPage.js
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import logo from "../images/logo.png"
import appStoreLogo from "../images/App-Store-Logo.png"
import googlePlayLogo from "../images/Google-Play-icon-logo.png"
import cartLogo from "../images/carts.png"
import instagramLogo from "../images/Instagram.png"
import tiktokLogo from "../images/tiktok.png"
import bananasBG from "../images/top_bananas.png"
import { Adsense } from "@ctrl/react-adsense"

const MainPage = () => {
  const [isIos, setIsIos] = useState(false)
  const [isAndroid, setIsAndroid] = useState(false)

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIsIos(true)
    } else if (/android/i.test(userAgent)) {
      setIsAndroid(true)
    }
  }, [])

  return (
    <div className="mx-auto text-center bg-gradient-to-r from-prim to-lightPrim flex-1">
      <div className="profile mb-4">
        {/* <img src={bananasBG} alt="header" className="max-w-[600px] mb-4" /> */}

        <img
          src={logo}
          alt="logo"
          className="w-40 h-40 mx-auto rounded-full mb-4"
        />

        <h1 className="text-xl font-bold text-white">Size Matters</h1>
        <p className="text-white mx-5">
          Try out our app, just point the camera to someone to find out their
          size!
        </p>
      </div>
      <div className="links mb-4 flex flex-col mx-10">
        {!isAndroid && (
          <Link
            id="ios-link"
            to="/introduction"
            className="button h-[50px] mb-5 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full border border-white inline-flex items-center mb-2 text-center"
          >
            <img src={appStoreLogo} alt="iOS Store" className="w-8 mr-2" />
            Size Matters - App store
          </Link>
        )}
        {!isIos && (
          <a
            id="android-link"
            href="https://play.google.com/store/apps/details?id=com.joaomutablep.sizematters"
            target="_blank"
            className="button h-[50px] mb-5 bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-full border border-white inline-flex items-center mb-2 text-center"
          >
            <img src={googlePlayLogo} alt="Google Play" className="w-8 mr-2" />
            Size Matters - Apps on Google Play
          </a>
        )}
        <a
          id="shop-link"
          href="https://shop.sizematters.app"
          target="_blank"
          className="button h-[50px] mb-5 bg-yellow-500 hover:bg-yellow-700 text-white py-2 px-4 rounded-full border border-white inline-flex items-center mb-2 text-center"
        >
          <img src={cartLogo} alt="Shop" className="w-8 mr-2" />
          Size Matters - Our Cool clothes
        </a>
        <Link
          to="/introduction"
          className="button h-[50px] mb-5 bg-purple-500 hover:bg-purple-700 text-white py-2 px-4 rounded-full border border-white inline-flex items-center mb-2 text-center"
        >
          <span>Try here on web!</span>
        </Link>
      </div>
      <div className="social-links mt-4">
        <p className="text-white mb-4">Does the size really matter?</p>
        <a
          href="https://instagram.com/sizemattersapp"
          target="_blank"
          className="button h-[50px] w-[150px] mr-5 mb-5 bg-pink-500 hover:bg-pink-700 text-white py-2 px-4 rounded-full border border-white inline-flex items-center mb-2"
        >
          <img src={instagramLogo} alt="Instagram" className="w-6 h-6 mr-2" />
          Instagram
        </a>
        <a
          href="https://tiktok.com/@sizemattersapp"
          target="_blank"
          className="button h-[50px] w-[150px] bg-black hover:bg-gray-800 text-white py-2 px-4 rounded-full border border-white inline-flex items-center"
        >
          <img src={tiktokLogo} alt="TikTok" className="w-6 h-6 mr-2" />
          TikTok
        </a>
      </div>
    </div>
  )
}

export default MainPage
