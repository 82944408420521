// src/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../images/logo.png";

const Header = () => {
  return (
    <header className="bg-prim p-4 flex justify-between items-center text-white z-10 h-[50px] max-h-[50px] pt-5">
      <div>
        <Link to="/">
        <img src={logo} alt="Size Matters" className="w-16 h-16" />
        </Link>
      </div>
      <nav>
        <Link to="/" className="mr-4 hover:underline">Home</Link>
        
        <Link to="/camera" className="hover:underline">Camera</Link>
        
        <Link to="https://shop.sizematters.app" className="hover:underline ml-4">Shop</Link>
      </nav>
    </header>
  );
};

export default Header;
